// Carousel entry point

// IMPORTANT PER A QUE FUNCIONI BÉ AMB BROWSERIFY:
// https://github.com/kenwheeler/slick/issues/1010#issuecomment-124861077
require('slick-carousel');

import Carousel from '../_modules/carousel/carousel';

$(() => {
  new Carousel().init();
});
