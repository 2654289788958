/**
 * @param {HTMLElement} el
 * @param {Array} arr - array of class names
 */
function addClassesToElement(el, arr) {
  arr.forEach(className => {
    el.classList.add(className);
  });
}

/**
 * @param {HTMLElement} el
 * @param {Array} arr - array of html elements
 */
function appendChildrenToElement(el, arr) {
  arr.forEach(child => {
    el.appendChild(child);
  });
}

/**
 * @returns {HTMLElement}
 */
function pauseButtonElement() {
  const button = document.createElement("button");
  const smallPauseIcon = document.createElement("span");
  const largePauseIcon = document.createElement("span");
  const smallPlayIcon = document.createElement("span");
  const largePlayIcon = document.createElement("span");

  addClassesToElement(button, ["carousel__pause", "is-playing"]);
  addClassesToElement(smallPauseIcon, ["icon", "icon--slider-pause-small"]);
  addClassesToElement(largePauseIcon, ["icon", "icon--slider-pause-large"]);
  addClassesToElement(smallPlayIcon, ["icon", "icon--slider-play-small"]);
  addClassesToElement(largePlayIcon, ["icon", "icon--slider-play-large"]);

  appendChildrenToElement(button, [
    smallPauseIcon,
    largePauseIcon,
    smallPlayIcon,
    largePlayIcon
  ]);

  // Should be set depending on initial state
  // button.setAttribute("aria-label", "Aturar/iniciar carrusel");

  return button;
}

const Carousel = function() {
  const self = this;

  self.config = {
    carousel: $("[data-carousel]"),
    slide: $("[data-carousel-slide]"),
    slickSettings: {
      arrows: false,
      dots: true,
      dotsClass: "carousel__dots",
      // focusOnChange: true,
      // accessibility: true,
      easing: "ease",
      mobileFirst: true,
      swipe: true,
      autoplaySpeed: 8000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            swipe: false,
            arrows: true,
            prevArrow:
              '<button type="button" aria-label="Diapositiva anterior" class="carousel__arrow -left"><span class="icon icon--carousel--prev"></span></button>',
            nextArrow:
              '<button type="button" aria-label="Diapositiva següent" class="carousel__arrow -right"><span class="icon icon--carousel--next"></span></button>'
          }
        }
      ]
    },
    fadeOnSlide: $("[data-fade-on-slide]")
  };

  const $carousels = self.config.carousel;
  const slickSettings = self.config.slickSettings;

  self.initCarousel = $carousel => {

    // Check for autoplay in slider's attributes
    const autoplayEnabled = $carousel.attr("data-autoplay") === "true";
    if (autoplayEnabled) {
      slickSettings.autoplay = true;
    }

    // Check for adaptative height in slider's attributes
    if ($carousel.attr("data-adaptive-height") === "true") {
      slickSettings.adaptiveHeight = true;
    }

    // Add text in Catalan in dot buttons and delete 'aria-label'
    const replaceDotButtonsText = () => {
      const $buttons = $(".carousel__dots").find("button");
      Array.prototype.forEach.call($buttons, button => {
        const currText = button.textContent;
        const newText = `Diapositiva ${currText}`;
        button.textContent = newText;
      });
    };

    // Delete aria-label clashing with button's text
    const deleteDotButtonsAriaLabels = () => {
      const $buttons = $(".carousel__dots").find("button");
      Array.prototype.forEach.call($buttons, button => {
        button.removeAttribute('aria-label');
      });
    }

    // Replace 'aria-describedby' by 'aria-labelledby' in the slides
    const replaceDescribedByLabelled = () => {
      const slides = document.querySelectorAll(".slick-slide:not(.slick-cloned)");

      slides.forEach( (slide,index) => {
        // const ariaLabelledyBy = slide.getAttribute('aria-describedby');

        // Adding it manually due to lack of slider's timing control
        const slideLabel = `slick-slide-control0${index}`;
        slide.setAttribute('aria-labelledby', slideLabel);
        slide.removeAttribute('aria-describedby');
      });
    }

    // Alternate the pauseButton's aria-labels based on the state
    const pauseButtonHandler = () => {
      const pauseButton = pauseButtonElement();

      $carousel[0].appendChild(pauseButton);

      if (autoplayEnabled) {
        pauseButton.setAttribute("aria-label", "Aturar carrusel");
        $carousel[0].setAttribute("aria-live", "polite");
      } else {
        pauseButton.setAttribute("aria-label", "Iniciar carrusel");
        $carousel[0].setAttribute("aria-live", "off");
      }

      const clickCb = e => {
        e.preventDefault();

        if (pauseButton.classList.contains("is-playing")) {
          pauseButton.classList.remove("is-playing");
          pauseButton.setAttribute("aria-label", "Iniciar carrusel");
          $carousel.slick("slickPause");
          $carousel[0].setAttribute("aria-live", "off");
        } else {
          pauseButton.classList.add("is-playing");
          pauseButton.setAttribute("aria-label", "Aturar carrusel");
          $carousel.slick("slickNext");
          $carousel.slick("slickPlay");
          $carousel[0].setAttribute("aria-live", "polite");
        }
      };

      pauseButton.addEventListener("click", clickCb);
    };

    // Callback for slider's initialization
    const initCb = () => {

      if (autoplayEnabled) {
        pauseButtonHandler();
      }

      // Plyr adds "tabindex=0" to target DIV but Slick Carousel doesn't disable it
      // Normal videos are managed in plyr.js and cloned videos are managed here
      var clonedVideos = document.querySelectorAll('.slick-cloned [tabindex="0"]');
      clonedVideos.forEach(function(thisOne){
        thisOne.removeAttribute("tabindex");
      })
    };

    $carousel.on("init", initCb);
    $carousel.on("breakpoint", initCb);

    $carousel.slick(slickSettings);
    self.fadeOnSlide($carousel);

    // Actions after slider's initialization and on change
    replaceDotButtonsText();
    replaceDescribedByLabelled();
    deleteDotButtonsAriaLabels();

    // Due to the sliders errors regarding accessibility
    // some changes have to be applied every time that
    // the slides are changed. But there are conflicts
    $carousel.on("setPosition", deleteDotButtonsAriaLabels);
  };

  self.fadeOnSlide = $carousel => {
    const $fadeOnSlide = $carousel.find(self.config.fadeOnSlide);
    $fadeOnSlide.addClass("is-visible");

    $carousel.on("afterChange", () => {
      $fadeOnSlide.addClass("is-visible");
    });

    $carousel.on("beforeChange", () => {
      $fadeOnSlide.removeClass("is-visible");
    });
  };

  self.init = () => {
    $carousels.each(function() {
      var $this = $(this);
      var $slide = $this.find(self.config.slide);
      if ($slide.length > 1) {
        self.initCarousel($this);
      }
    });
  };
};

export default Carousel;
